/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 96%;
}
#home {
  width: 270px;
  margin-top: 18px;
  margin-bottom: -12px;
}
#multimood {
  margin-top: 102px;
}
#services {
  display: block;
  float: left;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 17px;
}
#services .meta {
  font-size: 16px;
  color: #fff;
  border-right: 2px solid #fff;
  padding-right: 5px;
  line-height: 1;
}
#services .meta:hover,
#services .meta:focus {
  color: #4a4430;
}
#services .meta.service_copyright {
  pointer-events: none;
  padding-right: 0;
  border: none;
}
#services .meta.auth {
  display: none;
}
h2 {
  font-size: 24px;
}
.bookingmaskwidth {
  left: 0;
  width: 100%;
}
.bookingmaskwidth.cb-navi-fixed {
  width: 96%;
  left: 2%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.area .part,
.area > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.area .tiny {
  width: 48%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 2%;
  margin-left: 2%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96%;
}
.area > .slim .tiny {
  width: 46%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.cb-layout2 .main .tiny {
  width: 31.33333333%;
}
.cb-layout2 .main > .slim {
  width: 33.33333333%;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 3%;
  margin-left: 3%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 94%;
}
.cb-layout2 .main > .slim .tiny {
  width: 94%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north h2,
.north .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.north .part,
.north > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.north .tiny {
  width: 31.33333333%;
}
.north > .slim {
  width: 33.33333333%;
}
.north > .slim h2,
.north > .slim .foot,
.north > .slim .part,
.north > .slim.grid table {
  margin-right: 3%;
  margin-left: 3%;
}
.north > .slim .part,
.north > .slim.grid table {
  width: 94%;
}
.north > .slim .tiny {
  width: 94%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
@media only screen and (max-width: 1023px) {
  .main {
    width: 102%;
    margin-left: -1%;
  }
  .cb-layout2 .main {
    width: 102%;
    margin-left: -1%;
  }
  .north {
    width: 102%;
    margin-left: -1%;
  }
}
.cb-layout2 div.main div.unit.wide {
  width: 66.66666667%;
}
.cb-layout2 div.main div.unit.wide div.part {
  margin-left: 1.5%;
  margin-right: 1.5%;
  width: 97%;
}
.cb-layout2 div.main div.unit.wide div.part.tiny {
  width: 47%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */